.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navbar {
  font-size: 18px;
}

.nav-item {
  padding-right: 1em;
}

.navbar-collapse {
  background-color: #42b6f5; /* Imposta il colore di sfondo del menu responsivo */
  padding: 10px; /* Aggiungi spaziatura per una migliore leggibilità */
}

.navbar-collapse a {
  color: #fff; /* Imposta il colore del testo dei link nel menu responsivo */
}

/* Modifica il colore del bottone del menu (hamburger) quando è attivo */
.navbar-toggler-icon {
  background-color:#42b6f5;
}

.dropdown-menu {
  --bs-dropdown-link-active-color: #42b6f5;
  --bs-dropdown-link-hover-color: #42b6f5
}
/* File CSS personalizzato */
.dropdown-menu a {
  color: #ffffff; /* Cambia il colore del testo dei link nel menu dropdown */;
}

.dropdown-menu a:hover {
  color: #42b6f5; /* Cambia il colore del testo dei link al passaggio del mouse */
}

/* File CSS personalizzato */
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active {
  background-color: #42b6f5; /* Imposta il colore di sfondo per le opzioni selezionate */
  color: #fff; /* Imposta il colore del testo per le opzioni selezionate */
}
/* File CSS personalizzato */
.dropdown-item.active {
  background-color: #42b6f5 !important; /* Imposta il colore di sfondo per l'opzione selezionata */
  color: #fff !important; /* Imposta il colore del testo per l'opzione selezionata */
}

/* Sovrascrivi il colore del testo delle voci di menu al passaggio del mouse */
.navbar-nav .nav-link:hover,
.navbar-nav .nav-item:hover .nav-link {
  color: black !important; /* Usa !important per assicurarti che questa regola venga applicata */
}



