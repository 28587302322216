.accordion {
    --bs-accordion-active-bg: lightgray !important;
    --bs-accordion-bg: #42b6f5;
    width: 80%;
    margin-top: 1em;
}

/* Accordion button con stato 'collapsed' (chiuso) */
.accordion-button.collapsed {
    color: white;
    background-color: #42b6f5;  /* Colore di sfondo quando è chiuso */
    font-weight: bold;
}

/* Accordion button con stato 'collapsed' (aperto) */
.accordion-button:not(.collapsed) {
    color: #42b6f5;  /* Colore del testo quando è aperto */
    background-color: white;  /* Colore di sfondo quando è aperto */
    font-weight: bold;
}

.accordion-body {
    background-color: white !important;
}

.accordion-button:focus {
    box-shadow: var(--accordion-btn-focus-box-shadow);
}

/* Se vuoi rimuovere completamente l'ombreggiatura, imposta la variabile su 'none' */
:root {
    --accordion-btn-focus-box-shadow: none;
}

@media (max-width: 600px) {
    width: 95%;
}
