.btn.my-btn {
    border-top-color: #42b6f5;
    background-color: #42b6f5;
    border-bottom-color: transparent;
    border-right-color: #42b6f5;
    border-left-color: #42b6f5;
    color: white;
    border-width: 2px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 1px;
}
.btn.my-btn.active,
.btn.my-btn:active,
.btn.my-btn.show {
    background-color: #F8F8F8;
    border-top-color: #42b6f5;
    border-bottom-color: transparent;
    border-right-color: #42b6f5;
    border-left-color: #42b6f5;
    color: #42b6f5;
}



.btn.my-btn:hover {
    background-color: #F8F8F8 !important;
    border-top-color: #F8F8F8;
    border-bottom-color: transparent;
    border-right-color: #F8F8F8;
    border-left-color:#F8F8F8;
    color: #42b6f5;
}

.btn.my-btn:focus {
    background-color: #F8F8F8 !important;
    border-top-color: #42b6f5;
    border-bottom-color: transparent;
    border-right-color: #42b6f5;
    border-left-color: #42b6f5;
    color: #42b6f5;

}



.card.my-card {
    padding: 30px;
    background-color: #F8F8F8;
    margin-top: -5px;
    height: 400px;
    border-color: #42b6f5;
    border-width: 2px;
}

.container-fluid.my-container {
    max-width: 1000px;
}

@media (max-width: 900px) {
    .container-fluid.my-container {
        width: 95%;
    }
}

@media (max-width: 900px) {
    .p-flex {
        flex-direction: column;
        align-self: center;
    }

    .btn.my-btn {
        margin-bottom: 1px; /* Aggiungi uno spazio tra i bottoni impilati */
        max-width: 400px;
    }
}
